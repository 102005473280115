import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Typography } from '@mui/material';
import Label from './Label';
//css from home
import '../pages/home/home.css';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const logo = (
    <Box sx={{ flexGrow: 1, width: 35, height: 35, display: 'flex', alignItems: 'center', ...sx }}>
      <img src="/favicon/logo.png" alt="omnibal-logo" />
      <Typography variant="h6" sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
        <span className="title_keyword2">Omnibal</span>
        <Label color="error" sx={{ ml: 0.5 }}>Labs</Label>
      </Typography>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }
  return <RouterLink to="/" style={{ textDecoration: 'none' }}>{logo}</RouterLink>;
}
